const getDate = dateStr => {
  const date = new Date(dateStr)
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}

const getCareerId = search => search.substring(10)

const sendAnalitycsThankYouEvent = ({ page, form }) => {
  const dataLayer = window.dataLayer || []
  dataLayer.push({
    event: "virtualEvent",
    vpv: `/virtual/${page}/thank-you`,
    masterevent: `${form}_submit`,
  })
}

export { getDate, getCareerId, sendAnalitycsThankYouEvent }
