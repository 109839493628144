import React, { useState, useEffect } from 'react';

import Layout from './../components/Layout';
import Seo from './../components/Seo';
import CareersForm from './../components/CareersForm/CareersForm';
import contactStyles from './../styles/contact.module.scss';
import edinburgh from './../assets/images/edinburgh.png';
import london from './../assets/images/london.png';
import sofia from './../assets/images/sofia.png';
import ContactForm from './../components/ContactForm/ContactForm';
import { getCareerId } from './../common/utils';

import worldMapSvg from './../assets/svg/contact-world-map.svg'

const Contact = ({ location }) => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  const renderForm = () => {
    if (!hasMounted) {
      return null;
    }

    const isCareersContact = location.search.includes('careerId');
    return isCareersContact ? <CareersForm careerId={getCareerId(location.search)} /> : <ContactForm backgroundColor='#ffffff' />;
  };
  
  return (
    <Layout>
      <Seo title={'Contact | TechPods'}/>
      <main>
        <section className={contactStyles.locations} id="locations">
          <h1 className="primary-title">Our locations</h1>
          <div className={contactStyles.locationsImages}>
            <div className={contactStyles.location}>
              <div>
                <img src={london} alt="London" />
                <h3>London</h3>
              </div>
              <div>
                <span>Huckletree West, 191 Wood Ln, White City<br />London W12 7FP</span>
                <a href="tel:+447376008701">Tel: (+44) 7376008701</a>
                <span>E-mail: <a href="mailto:hello@techpods.co">hello@techpods.co</a></span>
              </div>
            </div>
            <div className={contactStyles.location}>
              <div>
                <img src={sofia} alt="Sofia" />
                <h3>Sofia</h3>
              </div>
              <div>
                <span>Campus X, 31 Alexander Malinov Blvd. Sofia 1729</span>
                <a href="tel:++359877077672">Tel: (+359) 877077672</a>
                <span>E-mail: <a href="mailto:hello@techpods.co">hello@techpods.co</a></span>
              </div>
            </div>
            <div className={contactStyles.location}>
              <div>
                <img src={edinburgh} alt="Edinburgh" />
                <h3>Edinburgh</h3>
              </div>
              <div>
                <span>TechCube, 1 Summerhall Pl, Newington<br />Edinburgh EH9 1PL</span>
                <a href="tel:+447376008701">Tel: (+44) 7376008701</a>
                <span>E-mail: <a href="mailto:hello@techpods.co">hello@techpods.co</a></span>
              </div>
            </div>
          </div>
        </section>
        <section className={contactStyles.formContainer} id="form">
          <svg width="100%" viewBox="0 0 1438 846" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
              id="mask0"
              mask-type="alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="1438"
              height="846"
            >
              <rect width="1438" height="846" fill="url(#paint0_linear)" />
            </mask>
            <g mask="url(#mask0)">
              <g opacity="0.5">
                <path
                  d="M4.14739 842.865C55.3923 842.598 55.5303 887.918 106.775 887.785C158.02 887.519 157.744 842.198 208.989 842.065C260.234 841.798 260.372 887.119 311.617 886.985C362.862 886.719 362.585 841.398 413.83 841.265C465.075 840.998 465.213 886.319 516.458 886.186C567.703 885.919 567.427 840.599 618.672 840.465C669.916 840.199 670.055 885.519 721.299 885.386C772.544 885.119 772.268 839.799 823.513 839.665C874.758 839.399 874.896 884.719 926.141 884.586C977.386 884.32 977.109 838.999 1028.35 838.866C1079.6 838.599 1079.74 883.92 1130.98 883.786C1182.23 883.52 1182.09 838.199 1233.2 838.066C1284.44 837.799 1284.58 883.12 1335.82 882.987C1387.07 882.853 1386.93 837.399 1438.04 837.266"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M4.00659 800.476C55.2515 800.21 55.3898 845.53 106.635 845.397C157.88 845.13 157.603 799.81 208.848 799.676C260.093 799.41 260.231 844.73 311.476 844.597C362.721 844.33 362.445 799.01 413.69 798.877C464.934 798.61 465.073 843.931 516.317 843.797C567.562 843.531 567.286 798.21 618.531 798.077C669.776 797.81 669.914 843.131 721.159 842.997C772.404 842.731 772.127 797.41 823.372 797.277C874.617 797.01 874.755 842.331 926 842.198C977.245 841.931 976.969 796.611 1028.21 796.477C1079.46 796.211 1079.6 841.531 1130.84 841.398C1182.09 841.131 1181.95 795.811 1233.06 795.677C1284.3 795.411 1284.44 840.731 1335.68 840.598C1386.93 840.332 1386.79 795.011 1437.9 794.878"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M3.72942 757.955C54.9743 757.688 55.1124 803.009 106.357 802.875C157.602 802.609 157.326 757.288 208.571 757.155C259.816 756.888 259.954 802.209 311.199 802.075C362.444 801.809 362.167 756.488 413.412 756.355C464.657 756.088 464.795 801.409 516.04 801.276C567.285 801.009 567.009 755.689 618.254 755.555C669.499 755.289 669.637 800.609 720.881 800.476C772.126 800.209 771.85 754.889 823.095 754.755C874.34 754.489 874.478 799.809 925.723 799.676C976.968 799.41 976.691 754.089 1027.94 753.956C1079.18 753.689 1079.32 799.01 1130.56 798.876C1181.81 798.61 1181.67 753.289 1232.78 753.156C1284.02 752.889 1284.16 798.21 1335.41 798.077C1386.65 797.81 1386.51 752.489 1437.62 752.356"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M3.59074 715.566C54.8356 715.3 54.9737 760.62 106.219 760.487C157.463 760.221 157.187 714.9 208.432 714.767C259.677 714.5 259.815 759.821 311.06 759.687C362.305 759.421 362.029 714.1 413.273 713.967C464.518 713.7 464.656 759.021 515.901 758.888C567.146 758.621 566.87 713.3 618.115 713.167C669.36 712.901 669.498 758.221 720.743 758.088C771.988 757.821 771.711 712.501 822.956 712.367C874.201 712.101 874.339 757.421 925.584 757.288C976.829 757.021 976.553 711.701 1027.8 711.568C1079.04 711.301 1079.18 756.622 1130.43 756.488C1181.67 756.222 1181.53 710.901 1232.64 710.768C1283.88 710.501 1284.02 755.822 1335.27 755.688C1386.51 755.555 1386.37 710.101 1437.48 709.968"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M3.45598 673.178C54.7009 672.911 54.8389 718.232 106.084 718.099C157.329 717.832 157.052 672.512 208.297 672.378C259.542 672.112 259.68 717.432 310.925 717.299C362.17 717.032 361.894 671.712 413.139 671.579C464.384 671.312 464.522 716.632 515.767 716.499C567.011 716.233 566.735 670.912 617.98 670.779C669.225 670.512 669.363 715.833 720.608 715.699C771.853 715.433 771.577 670.112 822.822 669.979C874.066 669.712 874.205 715.033 925.449 714.9C976.694 714.633 976.418 669.313 1027.66 669.179C1078.91 668.913 1079.05 714.233 1130.29 714.1C1181.54 713.833 1181.4 668.513 1232.5 668.379C1283.75 668.113 1283.89 713.433 1335.13 713.3C1386.38 713.167 1386.24 667.713 1437.35 667.58"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M3.17668 630.657C54.4216 630.39 54.5597 675.711 105.805 675.577C157.049 675.311 156.773 629.99 208.018 629.857C259.263 629.59 259.401 674.911 310.646 674.778C361.891 674.511 361.615 629.19 412.859 629.057C464.104 628.791 464.242 674.111 515.487 673.978C566.732 673.711 566.456 628.391 617.701 628.257C668.946 627.991 669.084 673.311 720.329 673.178C771.574 672.911 771.297 627.591 822.542 627.458C873.787 627.191 873.925 672.512 925.17 672.378C976.415 672.112 976.139 626.791 1027.38 626.658C1078.63 626.391 1078.77 671.712 1130.01 671.578C1181.26 671.312 1181.12 625.991 1232.23 625.858C1283.47 625.592 1283.61 670.912 1334.85 670.779C1386.1 670.645 1385.96 625.192 1437.07 625.058"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M3.03801 588.27C54.2829 588.003 54.421 633.324 105.666 633.191C156.911 632.924 156.635 587.603 207.879 587.47C259.124 587.204 259.262 632.524 310.507 632.391C361.752 632.124 361.476 586.804 412.721 586.67C463.966 586.404 464.104 631.724 515.349 631.591C566.594 631.324 566.317 586.004 617.562 585.871C668.807 585.604 668.945 630.925 720.19 630.791C771.435 630.525 771.159 585.204 822.404 585.071C873.648 584.804 873.787 630.125 925.031 629.992C976.276 629.725 976 584.404 1027.24 584.271C1078.49 584.005 1078.63 629.325 1129.87 629.192C1181.12 628.925 1180.98 583.605 1232.09 583.471C1283.33 583.205 1283.47 628.525 1334.71 628.392C1385.96 628.125 1385.82 582.805 1436.93 582.672"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M2.9052 545.881C54.1501 545.615 54.2882 590.935 105.533 590.802C156.778 590.536 156.502 545.215 207.747 545.082C258.991 544.815 259.13 590.136 310.374 590.002C361.619 589.736 361.343 544.415 412.588 544.282C463.833 544.015 463.971 589.336 515.216 589.203C566.461 588.936 566.184 543.615 617.429 543.482C668.674 543.216 668.812 588.536 720.057 588.403C771.302 588.136 771.026 542.816 822.271 542.682C873.516 542.416 873.654 587.736 924.899 587.603C976.143 587.336 975.867 542.016 1027.11 541.883C1078.36 541.616 1078.5 586.937 1129.74 586.803C1180.98 586.537 1180.85 541.216 1231.95 541.083C1283.2 540.816 1283.34 586.137 1334.58 586.004C1385.83 585.737 1385.69 540.416 1436.79 540.283"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M2.62573 503.492C53.8706 503.225 54.0089 548.546 105.254 548.413C156.499 548.146 156.222 502.826 207.467 502.692C258.712 502.426 258.85 547.746 310.095 547.613C361.34 547.346 361.064 502.026 412.309 501.892C463.554 501.626 463.692 546.946 514.937 546.813C566.181 546.547 565.905 501.226 617.15 501.093C668.395 500.826 668.533 546.147 719.778 546.013C771.023 545.747 770.747 500.426 821.991 500.293C873.236 500.026 873.374 545.347 924.619 545.214C975.864 544.947 975.588 499.626 1026.83 499.493C1078.08 499.227 1078.22 544.547 1129.46 544.414C1180.71 544.147 1180.57 498.827 1231.67 498.693C1282.92 498.427 1283.06 543.747 1334.3 543.614C1385.55 543.347 1385.41 498.027 1436.52 497.894"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M2.48723 460.972C53.7321 460.705 53.8702 506.026 105.115 505.892C156.36 505.626 156.084 460.305 207.329 460.172C258.573 459.905 258.712 505.226 309.956 505.093C361.201 504.826 360.925 459.505 412.17 459.372C463.415 459.106 463.553 504.426 514.798 504.293C566.043 504.026 565.766 458.706 617.011 458.572C668.256 458.306 668.394 503.626 719.639 503.493C770.884 503.226 770.608 457.906 821.853 457.773C873.098 457.506 873.236 502.827 924.481 502.693C975.726 502.427 975.449 457.106 1026.69 456.973C1077.94 456.706 1078.08 502.027 1129.32 501.894C1180.57 501.627 1180.43 456.306 1231.54 456.173C1282.78 455.907 1282.92 501.227 1334.16 501.094C1385.41 500.96 1385.27 455.507 1436.38 455.373"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M2.20793 418.584C53.4528 418.317 53.5909 463.638 104.836 463.504C156.081 463.238 155.804 417.917 207.049 417.784C258.294 417.517 258.432 462.838 309.677 462.705C360.922 462.438 360.646 417.118 411.891 416.984C463.136 416.718 463.274 462.038 514.519 461.905C565.763 461.638 565.487 416.318 616.732 416.185C667.977 415.918 668.115 461.238 719.36 461.105C770.605 460.839 770.329 415.518 821.573 415.385C872.818 415.118 872.956 460.439 924.201 460.305C975.446 460.039 975.17 414.718 1026.41 414.585C1077.66 414.318 1077.8 459.639 1129.04 459.506C1180.29 459.239 1180.15 413.918 1231.26 413.785C1282.5 413.519 1282.64 458.839 1333.88 458.706C1385.13 458.439 1384.99 413.119 1436.1 412.985"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M2.06926 376.195C53.3141 375.929 53.4522 421.249 104.697 421.116C155.942 420.849 155.666 375.529 206.911 375.396C258.155 375.129 258.294 420.45 309.539 420.316C360.783 420.05 360.507 374.729 411.752 374.596C462.997 374.329 463.135 419.65 514.38 419.517C565.625 419.25 565.349 373.929 616.593 373.796C667.838 373.53 667.976 418.85 719.221 418.717C770.466 418.45 770.19 373.13 821.435 372.996C872.68 372.73 872.818 418.05 924.063 417.917C975.308 417.65 975.031 372.33 1026.28 372.197C1077.52 371.93 1077.66 417.251 1128.9 417.117C1180.15 416.851 1180.01 371.53 1231.12 371.397C1282.36 371.13 1282.5 416.451 1333.75 416.317C1384.99 416.051 1384.85 370.73 1435.96 370.597"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1.93628 333.674C53.1812 333.407 53.3194 378.728 104.564 378.594C155.809 378.328 155.533 333.007 206.778 332.874C258.023 332.608 258.161 377.928 309.406 377.795C360.651 377.528 360.374 332.208 411.619 332.074C462.864 331.808 463.002 377.128 514.247 376.995C565.492 376.728 565.216 331.408 616.461 331.275C667.705 331.008 667.844 376.329 719.089 376.195C770.333 375.929 770.057 330.608 821.302 330.475C872.547 330.208 872.685 375.529 923.93 375.395C975.175 375.129 974.899 329.808 1026.14 329.675C1077.39 329.408 1077.53 374.729 1128.77 374.596C1180.02 374.329 1179.88 329.009 1230.98 328.875C1282.23 328.609 1282.37 373.929 1333.61 373.796C1384.86 373.663 1384.72 328.209 1435.83 328.076"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1.65715 291.287C52.902 291.02 53.0401 336.341 104.285 336.207C155.53 335.941 155.254 290.62 206.499 290.487C257.743 290.22 257.882 335.541 309.126 335.408C360.371 335.141 360.095 289.821 411.34 289.687C462.585 289.421 462.723 334.741 513.968 334.608C565.213 334.341 564.936 289.021 616.181 288.887C667.426 288.621 667.564 333.941 718.809 333.808C770.054 333.541 769.778 288.221 821.023 288.088C872.268 287.821 872.406 333.142 923.651 333.008C974.895 332.742 974.619 287.421 1025.86 287.288C1077.11 287.021 1077.25 332.342 1128.49 332.209C1179.74 331.942 1179.6 286.621 1230.71 286.488C1281.95 286.222 1282.09 331.542 1333.33 331.409C1384.58 331.142 1384.44 285.822 1435.55 285.688"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1.52434 248.899C52.7692 248.632 52.9073 293.953 104.152 293.819C155.397 293.553 155.121 248.232 206.366 248.099C257.611 247.832 257.749 293.153 308.994 293.02C360.238 292.753 359.962 247.432 411.207 247.299C462.452 247.033 462.59 292.353 513.835 292.22C565.08 291.953 564.804 246.633 616.048 246.499C667.293 246.233 667.431 291.553 718.676 291.42C769.921 291.153 769.645 245.833 820.89 245.7C872.135 245.433 872.273 290.754 923.518 290.62C974.763 290.354 974.486 245.033 1025.73 244.9C1076.98 244.633 1077.11 289.954 1128.36 289.821C1179.6 289.554 1179.47 244.233 1230.57 244.1C1281.82 243.834 1281.96 289.154 1333.2 289.021C1384.45 288.887 1384.31 243.434 1435.41 243.3"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1.37981 206.51C52.6247 206.244 52.7627 251.564 104.008 251.431C155.252 251.164 154.976 205.844 206.221 205.711C257.466 205.444 257.604 250.764 308.849 250.631C360.094 250.365 359.818 205.044 411.063 204.911C462.307 204.644 462.446 249.965 513.69 249.831C564.935 249.565 564.659 204.244 615.904 204.111C667.149 203.844 667.287 249.165 718.532 249.032C769.777 248.765 769.5 203.444 820.745 203.311C871.99 203.045 872.128 248.365 923.373 248.232C974.618 247.965 974.342 202.645 1025.59 202.511C1076.83 202.245 1076.97 247.565 1128.21 247.432C1179.46 247.166 1179.32 201.845 1230.43 201.712C1281.67 201.445 1281.81 246.766 1333.06 246.632C1384.3 246.366 1384.16 201.045 1435.27 200.912"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1.10637 163.989C52.3512 163.723 52.4894 209.043 103.734 208.91C154.979 208.643 154.703 163.323 205.948 163.189C257.193 162.923 257.331 208.243 308.576 208.11C359.82 207.844 359.544 162.523 410.789 162.39C462.034 162.123 462.172 207.444 513.417 207.31C564.662 207.044 564.386 161.723 615.631 161.59C666.875 161.323 667.013 206.644 718.258 206.511C769.503 206.244 769.227 160.923 820.472 160.79C871.717 160.524 871.855 205.844 923.1 205.711C974.345 205.444 974.068 160.124 1025.31 159.99C1076.56 159.724 1076.7 205.044 1127.94 204.911C1179.19 204.644 1179.05 159.324 1230.15 159.191C1281.4 158.924 1281.54 204.245 1332.78 204.111C1384.03 203.845 1383.89 158.524 1435 158.391"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M0.965745 121.601C52.2106 121.334 52.3487 166.655 103.594 166.521C154.838 166.255 154.562 120.934 205.807 120.801C257.052 120.534 257.19 165.855 308.435 165.722C359.68 165.455 359.404 120.134 410.648 120.001C461.893 119.735 462.032 165.055 513.276 164.922C564.521 164.655 564.245 119.335 615.49 119.201C666.735 118.935 666.873 164.255 718.118 164.122C769.363 163.855 769.086 118.535 820.331 118.402C871.576 118.135 871.714 163.456 922.959 163.322C974.204 163.056 973.928 117.735 1025.17 117.602C1076.42 117.335 1076.56 162.656 1127.8 162.523C1179.05 162.256 1178.91 116.935 1230.01 116.802C1281.26 116.536 1281.4 161.856 1332.64 161.723C1383.89 161.589 1383.75 116.136 1434.86 116.002"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M0.827847 79.2125C52.0727 78.9459 52.2108 124.266 103.456 124.133C154.701 123.867 154.424 78.546 205.669 78.4127C256.914 78.1461 257.052 123.467 308.297 123.333C359.542 123.067 359.266 77.7462 410.511 77.613C461.755 77.3464 461.894 122.667 513.138 122.534C564.383 122.267 564.107 76.9465 615.352 76.8132C666.597 76.5466 666.735 121.867 717.98 121.734C769.225 121.467 768.949 76.1467 820.193 76.0134C871.438 75.7468 871.576 121.067 922.821 120.934C974.066 120.667 973.79 75.3469 1025.03 75.2137C1076.28 74.9471 1076.42 120.268 1127.66 120.134C1178.91 119.868 1178.77 74.5472 1229.88 74.4139C1281.12 74.1473 1281.26 119.468 1332.5 119.335C1384.3 118.801 1384.16 73.4808 1435.41 73.2142"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M0.550719 36.6923C51.7956 36.4258 51.9338 81.7463 103.179 81.613C154.424 81.3464 154.147 36.0259 205.392 35.8926C256.637 35.626 256.775 80.9465 308.02 80.8132C359.265 80.5466 358.989 35.2261 410.234 35.0928C461.617 34.8262 461.893 80.1468 513.138 79.8802C564.383 79.6136 564.106 34.293 615.351 34.1598C666.596 33.8932 666.734 79.2137 717.979 79.0804C769.224 78.8138 768.948 33.4933 820.193 33.36C871.438 33.0934 871.576 78.4139 922.82 78.2806C974.065 78.0141 973.789 32.6935 1025.03 32.5602C1076.28 32.2936 1076.42 77.6142 1127.66 77.4809C1178.91 77.2143 1178.77 31.8937 1229.88 31.7605C1281.12 31.4939 1281.26 76.8144 1332.5 76.6811C1383.75 76.4145 1383.61 31.094 1434.72 30.9607"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
                <path
                  d="M0.414964 -5.6959C51.6598 -5.96249 51.798 39.358 103.043 39.2248C154.288 38.9582 154.011 -6.36237 205.256 -6.49566C256.501 -6.76225 256.639 38.5583 307.884 38.425C359.129 38.1584 358.853 -7.16213 410.098 -7.29542C461.343 -7.56201 461.481 37.7585 512.726 37.6252C563.97 37.3586 563.694 -7.96189 614.939 -8.09519C666.184 -8.36178 666.322 36.9587 717.567 36.8255C768.812 36.5589 768.536 -8.76167 819.78 -8.89496C871.025 -9.16155 871.164 36.159 922.408 36.0257C973.653 35.7591 973.377 -9.56143 1024.62 -9.69472C1075.87 -9.96131 1076 35.3592 1127.25 35.2259C1178.49 34.9593 1178.36 -10.3612 1229.46 -10.4945C1280.71 -10.7611 1280.85 34.5595 1332.09 34.4262C1383.34 34.1596 1383.2 -11.161 1434.3 -11.2943"
                  stroke="#798FA8"
                  strokeOpacity="0.27"
                  strokeMiterlimit="10"
                />
              </g>
            </g>
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="719"
                y1="0"
                x2="719"
                y2="846"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#748CC2" />
                <stop offset="1" stopColor="#6D9FC7" />
              </linearGradient>
            </defs>
          </svg>
          {renderForm()}
        </section>
        <svg 
          width="100%"
          height="100%"
          viewBox="0 0 1440 656">
          <use xlinkHref={`${worldMapSvg}#world-map`}></use>
        </svg>
      </main>
    </Layout>
  );
};

export default Contact;
