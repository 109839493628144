import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

import contactFormStyles from './contact-form.module.scss';

const ContactForm = ({ backgroundColor }) => {
  const [ isRobot, setIsRobot ] = useState(true);
  const [ title, setTitle ] = useState('Contact TechPods')
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data, e) => {
    if(!isRobot) {
      const res = await axios.post('/sendmail', data);
      setTitle(res.data.success ? 'Thank you! We will contact you shortly.' : 'Oops, something wrong happened! Please try again later.');
      e.target.reset();
    } else {
      alert('Please confirm that you are not a robot!')
    }
  };

  const verify = (response) => {
    if (response) {
      setIsRobot(false)
    }
  };

  return (
    <div className={contactFormStyles.container} style={{ backgroundColor }}>
      <h2 className="secondary-title">{ title }</h2>
      <form onSubmit={handleSubmit(onSubmit)} className={contactFormStyles.form}>
        <fieldset>
          <div className={contactFormStyles.field}>
            <label htmlFor="name">Full Name</label>
            <input type="text" name="name" id="name" autoComplete="off" ref={register({ required: true })} />
            {errors.name && <span>This field is required</span>}
          </div>
          <div className={contactFormStyles.field}>
            <label htmlFor="email">E-mail</label>
            <input type="email" name="email" id="email" autoComplete="off" ref={register({ required: true })} />
            {errors.email && <span>This field is required</span>}
          </div>
          <div className={contactFormStyles.field}>
            <label htmlFor="company">Company</label>
            <input type="text" name="company" id="company" autoComplete="off" ref={register({ required: true })} />
            {errors.company && <span>This field is required</span>}
          </div>
          <div className={contactFormStyles.field}>
            <label htmlFor="message">Your message</label>
            <textarea name="message" id="message" autoComplete="off" ref={register({ required: true })}></textarea>
            {errors.message && <span>This field is required</span>}
          </div>
        </fieldset>
        <div className={contactFormStyles.actions}>
          <ReCAPTCHA
            onChange={verify}
            name="captcha"
            sitekey="6LcpUskZAAAAAKy95jNNAJVq9VJMoV6JPWFuNuY9"
          />
          <button type="submit" className="primary-btn">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;