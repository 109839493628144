import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';


import careersFormStyles from './careers-form.module.scss';

const CareersForm = ({ careerId }) => {
  const [ isRobot, setIsRobot ] = useState(true);
  const [ fileName, setFileName ] = useState('');
  const form = useRef(null);
  const [ title, setTitle ] = useState('Apply at TechPods')
  const { register, handleSubmit, errors } = useForm();

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }

  const onSubmit = async (data, e) => {
    if(!isRobot) {
      const formData = new FormData(form.current);
      formData.append = ('careerId', `${careerId}`);
      const res = await axios.post('/apply', formData, config);
      
      setTitle(res.data.success ? 'Thank you! We will contact you shortly.' : 'Oops, something wrong happened! Please try again later.');
      e.target.reset();
      setFileName('');
    } else {
      alert('Please confirm that you are not a robot!')
    }
  };


  const verify = (response) => {
    if (response) {
      setIsRobot(false)
    }
  };

  return (
    <div className={careersFormStyles.container}>
      <h2 className="secondary-title">{ title }</h2>
      <form onSubmit={handleSubmit(onSubmit)} className={careersFormStyles.form} ref={form}>
        <fieldset>
          <div className={careersFormStyles.field}>
            <label htmlFor="name">Full Name</label>
            <input type="text" name="name" id="name" autoComplete="off" ref={register({ required: true })} />
            {errors.name && <span>This field is required</span>}
          </div>
          <div className={careersFormStyles.field}>
            <label htmlFor="email">E-mail</label>
            <input type="email" name="email" id="email" autoComplete="off" ref={register({ required: true })} />
            {errors.email && <span>This field is required</span>}
          </div>
          <div className={careersFormStyles.fileField}>
            <label htmlFor="cv">Your CV</label>
            <p className={careersFormStyles.fileName}>{fileName}</p>
            <input
              type="file"
              name="cv"
              id="cv"
              ref={register({ required: true })}
              className={careersFormStyles.fileInput}
              onChange={(e) => setFileName(e.target.files[0].name)}
            />
            {errors.cv && <span>This field is required</span>}
          </div>
          <input
            style={{display: 'none'}}
            value={`${careerId}`}
            ref={register} 
            type="text"
            name="careerId"
            id="careerId"></input>
        </fieldset>
        <div className={careersFormStyles.actions}>
          <ReCAPTCHA
            onChange={verify}
            name="captcha"
            sitekey="6LcpUskZAAAAAKy95jNNAJVq9VJMoV6JPWFuNuY9"
          />
          <button type="submit" className="primary-btn">Apply</button>
        </div>
      </form>
    </div>
  );
};

export default CareersForm;